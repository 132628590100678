import React from 'react';

import Button from "../button";
import Constants from '../../utils/constants';
import Error from '../../asstes/images/cancel.svg';
import Success from '../../asstes/images/Check.svg';
import './styles/index.scss';

const { MODAL: { ERROR, SUCCESS } } = Constants;

const Modal = ({ status, show, message, externalCode = '' }) => {
  if (!show) return null;

  const titleToShow = status ? SUCCESS.TITLE : ERROR.TITLE;
  const messageToShow = status ? message ? message : SUCCESS.TEXT : ERROR.TEXT;
  const iconToShow = status ? Success : Error;

  const handleClick = () => window.location.href = 'https://kripton.la/';
  const handleContact = () => window.location.href = externalCode ? `https://kripton.la?code=${externalCode}` : 'https://kripton.la';

  return (
    <div className="modal__container">
      <div className="modal__card">
        <div className="modal__content">
          <h3 className='modal__content-title'>{titleToShow}</h3>
          <div>
            <img src={iconToShow} alt={titleToShow} />
          </div>
          <p className='modal__content-text' dangerouslySetInnerHTML={{ __html: messageToShow }} />
          <div className="modal__content-buttons">
            <Button
              title={status ? 'Aceptar' : 'Continuar' }
              type="primary"
              callbak={handleClick}
              className="modal__button"
            />
            {!status && (
              <Button
                title="Contactar"
                type="secondary"
                callbak={handleContact}
                className="modal__button-contact"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
